import { DataDogMonitor } from '../../adapters/datadog.monitor';
import { IS_NODE_ENV_PRODUCTION } from '../../variables';
import { AbstractMonitor } from './AbstractMonitor';
import { NoMonitor } from './NoMonitor';

const HomescreenMonitor = (enabled: boolean): AbstractMonitor => {
    const shouldUseRealMonitoring = enabled && IS_NODE_ENV_PRODUCTION;
    const monitor: AbstractMonitor = shouldUseRealMonitoring ? new DataDogMonitor() : new NoMonitor();

    if (enabled) {
        monitor.start();
    } else {
        monitor.disable();
    }
    return monitor;
};

export default HomescreenMonitor;
