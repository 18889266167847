import { PropsWithChildren } from 'react';
import { DateFormat } from '../../helpers/formatDate';
import { FormatDateContext } from './FormatDateContext';
import { DEFAULT_FORMAT_DATE_CONFIG } from './FromatDateConfig';

export interface FormatDateProviderProps {
    languageDateMap: Record<string, DateFormat>;
    languageShortDateMap: Record<string, DateFormat>;
    defaultDate?: DateFormat;
    defaultShortDate?: DateFormat;
}

export const FormatDateProvider = ({
    languageDateMap,
    languageShortDateMap,
    defaultDate = DEFAULT_FORMAT_DATE_CONFIG.date,
    defaultShortDate = DEFAULT_FORMAT_DATE_CONFIG.shortDate,
    children,
}: PropsWithChildren<FormatDateProviderProps>) => {
    return (
        <FormatDateContext.Provider
            value={{
                date: { map: languageDateMap, defaultFormat: defaultDate },
                shortDate: { map: languageShortDateMap, defaultFormat: defaultShortDate },
            }}
        >
            {children}
        </FormatDateContext.Provider>
    );
};
