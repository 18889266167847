/* eslint-disable camelcase */
import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SITE, GIT_REF, GIT_SHA, MODE } from '../variables';
import {
    AbstractMonitor as Monitor,
    AbstractMonitorContext as MonitorContext,
    Organisation,
} from '../plugins/monitor/AbstractMonitor';
import { User } from '../types';
import { deleteCookieByName } from '../plugins/cookie/deleteCookieByName';

const DATADOG_COOKIE_NAMES = ['_dd_s'];

export class DataDogMonitor extends Monitor {
    start() {
        datadogRum.init({
            applicationId: DATADOG_APPLICATION_ID,
            clientToken: DATADOG_CLIENT_TOKEN,
            site: DATADOG_SITE,
            service: 'homescreen',
            env: MODE,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackFrustrations: true,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'mask',
            version: GIT_SHA,
            silentMultipleInit: true,
        });
        datadogRum.startSessionReplayRecording();
    }

    disable(): void {
        DATADOG_COOKIE_NAMES.forEach((name) => {
            deleteCookieByName(name);
        });
    }

    action(action: string, context?: MonitorContext): void {
        datadogRum.addAction(action, {
            package: `homescreen@${MODE}`,
            git_sha: GIT_SHA,
            git_ref: GIT_REF,
            context: {
                ...context,
                ...this.getContext(),
            },
        });
    }

    error(error: unknown, context?: MonitorContext): void {
        datadogRum.addError(error, {
            package: `homescreen@${MODE}`,
            git_sha: GIT_SHA,
            git_ref: GIT_REF,
            context: {
                ...context,
                ...this.getContext(),
            },
        });
    }

    setUser(user: User) {
        datadogRum.setUserProperty('id', user.id);
        datadogRum.setUserProperty('type', user.isCpEmployee ? 'admin' : 'user');
    }

    setAccount(organisation: Organisation) {
        const datadogKeysMap: Record<string, string> = {
            isTest: 'is_test',
        };
        Object.entries(organisation).forEach(([key, value]) => {
            datadogRum.setUserProperty(`account.${datadogKeysMap[key] ?? key}`, value);
        });
    }
}
