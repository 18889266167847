import { DATADOG_CLIENT_TOKEN, DATADOG_SITE, GIT_SHA, MODE } from '../variables';

import type { Logger as DDLogger } from '@datadog/browser-logs/cjs/domain/logger';
import { deleteCookieByName } from '../plugins/cookie/deleteCookieByName';
import { AbstractLogger as Logger, LogContext } from '../plugins/logger/AbstractLogger';

export class DataDogLogger implements Logger {
    private readonly cookieNames = ['_dd_s'];
    private logger: DDLogger | undefined;

    constructor(private isAllowed: boolean) {
        if (isAllowed) {
            import('@datadog/browser-logs').then((module) => {
                module.datadogLogs.init({
                    clientToken: DATADOG_CLIENT_TOKEN,
                    site: DATADOG_SITE,
                    forwardErrorsToLogs: true,
                    sampleRate: 100,
                    env: MODE,
                    service: 'homescreen',
                    version: GIT_SHA,
                });
                this.logger = module.datadogLogs.logger;
            });
        } else {
            //remove cookie if not used
            this.cookieNames.forEach((name) => deleteCookieByName(name));
        }
    }

    error(message: string, context?: LogContext): void {
        if (this.isAllowed && this.logger) {
            this.logger.error(message, context);
        }
    }

    info(message: string, context?: LogContext): void {
        if (this.isAllowed && this.logger) {
            this.logger.info(message, context);
        }
    }

    warn(message: string, context?: LogContext): void {
        if (this.isAllowed && this.logger) {
            this.logger.warn(message, context);
        }
    }

    debug(message: string, context?: LogContext): void {
        if (this.isAllowed && this.logger) {
            this.logger.debug(message, context);
        }
    }
}
