import { useTranslation } from 'react-i18next';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, ButtonBase } from '@mui/material';

import { Language } from '../variables';

const useStyle = makeStyles(({ palette }: Theme) => ({
    root: {
        borderRadius: '4px',
        padding: '8px 10px',
        textTransform: 'uppercase',
        color: palette.text.primary,
    },
    selected: {
        color: palette.primary.main,
    },
}));
export interface LanguageButtonProps {
    language: Language;
    onLanguageClick: (toLanguage: Language, target: EventTarget) => void;
}

export const LanguageButton = ({ language, onLanguageClick }: LanguageButtonProps) => {
    const {
        i18n: { resolvedLanguage },
    } = useTranslation();

    const classes = useStyle();
    const isSelected = language === resolvedLanguage;

    return (
        <Box
            component={ButtonBase}
            className={`${classes.root} ${isSelected ? classes.selected : ''}`}
            onClick={(evt) => onLanguageClick(language, evt.target)}
        >
            {language.substring(0, 2)}
        </Box>
    );
};
