import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell } from '@climatepartner/app-shell';
import { themeOptions } from '../helpers/themeOptions';
import LanguagePicker from './LanguagePicker';
import { getUserOrganizationName } from '../helpers/cim';
import { useAuth } from '../plugins/auth/AuthProvider';
import { resolveOIDCConfig } from '../helpers/tokenUtils';
import { useMonitor } from '../plugins/monitor/useMonitor';
import { AUTH_ENV } from '../variables';

export const Layout = () => {
    const { isAuthenticated, user } = useAuth();
    const { accessToken } = user ?? {};
    const [orgName, setOrgName] = useState('');
    const oidcConfig = resolveOIDCConfig(window.$cp?.oidc);
    const monitor = useMonitor();

    useEffect(() => {
        if (isAuthenticated && accessToken && orgName === '') {
            getUserOrganizationName(window.$cp.cimServiceApiUrl, accessToken)
                .then(setOrgName)
                .catch((error) => {
                    monitor.error('Failed to get organisation name', { error });
                });
        }
    }, [accessToken, isAuthenticated, monitor, orgName]);

    if (!oidcConfig) {
        return null;
    }

    return (
        <AppShell
            theme={themeOptions}
            oidcConfig={oidcConfig}
            authProvider="frontegg"
            fronteggEnvironment={AUTH_ENV}
            header={{
                slots: {
                    middle: orgName,
                },
            }}
            slots={{
                languagePicker: <LanguagePicker />,
            }}
            sidebar={{
                currentServiceId: 'homescreen',
            }}
        >
            <Outlet />
        </AppShell>
    );
};
