export const parse = <L>(languages: Array<string>, defaultLanguage: string, locale: string | undefined): L => {
    const direct = languages.find((l) => l === locale);
    if (direct) {
        return direct as L;
    }
    const result = languages.find((l) => l === (locale || '').substring(0, 2).toLowerCase()) || defaultLanguage;
    return result as L;
};

export const parseLanguage = (language: string): 'en-GB' | 'de-DE' => {
    return parse<'en-GB' | 'de-DE'>(['de-DE', 'en-GB'], 'en-GB', language);
};
