export type ServiceId = string;

export const resolveOIDCConfig = (config?: Window['$cp']['oidc']) => {
    if (
        config === null ||
        typeof config !== 'object' ||
        !Object.prototype.hasOwnProperty.call(config, 'client_id') ||
        !Object.prototype.hasOwnProperty.call(config, 'issuer')
    ) {
        return undefined;
    }
    const resolvedRealm = config.issuer.includes('/climatepartner-admin/') ? 'admin' : 'user';
    return {
        defaultRealm: resolvedRealm,
        openidRealmConfig: {
            [resolvedRealm]: {
                clientId: config.client_id,
                url: `${config.issuer}/.well-known/openid-configuration`,
            },
        },
    };
};
