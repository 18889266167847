import { createRoot } from 'react-dom/client';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { FeatureToggleInit } from './plugins/featureToggles';
import { refresh } from './refresh';
import { IS_NODE_ENV_PRODUCTION } from './variables';
import { AbstractMonitor } from './plugins/monitor/AbstractMonitor';
import HomescreenMonitor from './plugins/monitor/HomescreenMonitor';
import HomescreenLogger from './plugins/logger/HomescreenLogger';
import { AbstractLogger, LogLevel } from './plugins/logger/AbstractLogger';
import { MonitorProvider } from './plugins/monitor/MonitorProvider';
import { LoggerProvider } from './plugins/logger/LoggerProvider';
import { I18nextProvider } from './plugins/translations';
import { ThemeProvider } from './components/ThemeProvider';

type Tracking = {
    monitor: AbstractMonitor;
    logger: AbstractLogger;
    logLevel: LogLevel;
};

const initTracking = (): Tracking => {
    const analyticsCookiesEnabled = document.cookie.includes('analytics:yes');
    const monitor = HomescreenMonitor(analyticsCookiesEnabled);
    const logger = HomescreenLogger(analyticsCookiesEnabled);
    const logLevel: LogLevel = IS_NODE_ENV_PRODUCTION ? 'WARN' : 'DEBUG';

    document.addEventListener('cookieyes_consent_update', (eventData: any) => {
        const { accepted } = eventData.detail;
        if (accepted.includes('analytics')) {
            window.location.reload();
        }
    });

    return { monitor, logger, logLevel };
};

window.addEventListener('load', async () => {
    await refresh(() => {
        const configcatApiKey = window.$cp.config_cat_sdk_key;

        const { monitor, logger, logLevel } = initTracking();

        if (!configcatApiKey) {
            console.error('ConfigCat missing apiKey');
        } else {
            FeatureToggleInit(configcatApiKey);
        }

        const container = document.getElementById('root');
        if (!container) {
            throw new Error('Root container could not be found.');
        }

        const root = createRoot(container);

        root.render(
            <I18nextProvider>
                <ThemeProvider>
                    <MonitorProvider monitor={monitor}>
                        <LoggerProvider logger={logger} logLevel={logLevel}>
                            <ErrorBoundary>
                                <App />
                            </ErrorBoundary>
                        </LoggerProvider>
                    </MonitorProvider>
                </ThemeProvider>
            </I18nextProvider>,
        );
    });
});
