import { AbstractLogger } from './AbstractLogger';

export class NoLogger implements AbstractLogger {
    debug(): void {
        /* do nothing*/
    }

    error(): void {
        /* do nothing*/
    }

    info(): void {
        /* do nothing*/
    }

    warn(): void {
        /* do nothing*/
    }
}
