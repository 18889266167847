import { runFronteggAuth } from './frontegg.auth';
import { AuthInfo } from './types';

type AuthInfoWithProvider = AuthInfo;

export const authenticate = (): AuthInfoWithProvider => {
    const authInfo = runFronteggAuth();

    if (authInfo.isAuthenticated && authInfo.user) {
        saveToken(authInfo.user?.accessToken);
    }

    return authInfo;
};

const saveToken = (token: string) => {
    localStorage.setItem('access_token', token);
};
