import { GlobalProps } from '@emotion/react';
import { Theme, ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
    palette: {
        error: {
            main: '#AA1E00',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                size: 'small',
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '24px 24px 0px 24px',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '16px 24px 24px 24px',
                },
            },
        },
    },
};

export const getGlobalStyles = (theme: Theme): GlobalProps['styles'] => ({
    a: { color: theme.palette.primary.main },
    main: {
        paddingTop: '16px',
        maxWidth: '1516px',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});
