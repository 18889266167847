import { DataDogLogger } from '../../adapters/datadog.logger';
import { IS_NODE_ENV_PRODUCTION } from '../../variables';
import { AbstractLogger as Logger } from './AbstractLogger';
import { NoLogger } from './NoLogger';

const HomescreenLogger = (enabled: boolean): Logger => {
    const logger: Logger = IS_NODE_ENV_PRODUCTION ? new DataDogLogger(enabled) : new NoLogger();
    return logger;
};
export default HomescreenLogger;
