import { BUILD_DATE } from './buildInfo';

const getServerTimeAndBuildTime = async (): Promise<[Date, Date]> => {
    const {
        severTime: serverTimeAsString,
        body: { date: buildTimeAsString },
    } = await fetch('/buildInfo.json?' + Date.now(), {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
    }).then(async (r) => {
        const severTime = r.headers.get('date') as string;
        return { severTime, body: await r.json() };
    });
    return [new Date(serverTimeAsString), new Date(buildTimeAsString)];
};

export const refresh = async (onSuccess: () => Promise<void> | void) => {
    const [serverTime, buildTime] = await getServerTimeAndBuildTime();
    const currentBuildDate = new Date(BUILD_DATE);
    const refreshTime = new Date(serverTime);
    //maximum caching time of cloud-front
    refreshTime.setSeconds(refreshTime.getSeconds() + 30);

    if (refreshTime.getTime() > buildTime.getTime() && buildTime.getTime() !== currentBuildDate.getTime()) {
        window.location.reload();
    } else {
        await onSuccess();
    }
};
