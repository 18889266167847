import { ReactNode, createContext, useContext } from 'react';
import { AuthProvider as CPAuthProvider } from '@climatepartner/hooks';
import { Spinner } from '@climatepartner/components';
import { authenticate } from './authenticate';
import { AUTH_ENV } from '../../variables';

type Props = { children: ReactNode };

type AuthInfo = ReturnType<typeof authenticate>;

const defautlAuthInfo = {
    isAuthenticated: false,
    user: null,
    provider: 'frontegg' as const,
    hasPermission: (_: string) => false,
    logout: (_: string) => void 0,
};

const AuthContext = createContext<AuthInfo>(defautlAuthInfo);

export const InternalAuthProvider = ({ children }: Props) => {
    const auth = authenticate();
    const { isAuthenticated, user } = auth;

    if (!isAuthenticated || !user) {
        return <Spinner />;
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const AuthProvider = (props: Props) => {
    return (
        <CPAuthProvider environment={AUTH_ENV}>
            <InternalAuthProvider {...props} />
        </CPAuthProvider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
