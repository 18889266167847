import { featureToggleClient } from './featureToggles';
import { FeatureToggleKey } from './FeatureToggleKey';

export const isFeatureToggleEnabled = async (
    key: FeatureToggleKey,
    defaultValue = false,
    userId: string | undefined = undefined,
): Promise<boolean> => {
    const userIdentifier = userId ?? featureToggleClient?.getUserIdentifier() ?? '';

    return !!(await featureToggleClient.configCatClient?.getValueAsync(key, defaultValue, {
        identifier: userIdentifier,
    }));
};
