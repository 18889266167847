import { Component, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import GenericErrorPage from './GenericErrorPage';
import { useLogger } from '../plugins/logger/useLogger';
import { useMonitor } from '../plugins/monitor/useMonitor';
import { AbstractLogger } from '../plugins/logger/AbstractLogger';
import { AbstractMonitor } from '../plugins/monitor/AbstractMonitor';

type Props = {
    logger: AbstractLogger;
    monitor: AbstractMonitor;
};

type State = {
    error?: Error;
    hasError: boolean;
};

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
    constructor(props: PropsWithChildren<Props>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: any) {
        this.props.logger.error(error);
        this.props.monitor.error(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Stack flex={1} justifyContent="center" alignItems="center">
                    <GenericErrorPage />
                </Stack>
            );
        }

        return this.props.children;
    }
}

export default ({ children }: PropsWithChildren) => {
    const logger = useLogger();
    const monitor = useMonitor();
    return (
        <ErrorBoundary logger={logger} monitor={monitor}>
            {children}
        </ErrorBoundary>
    );
};
