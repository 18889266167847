export const defined = <T>(value: T | undefined, defaultValue: T): T => {
    if (typeof value !== 'undefined' && value !== null) {
        return value;
    }
    return defaultValue;
};

export interface DispatchCustomEventOption<T> {
    target?: EventTarget;
    bubble?: boolean;
    canBeCancelled?: boolean;
    detail: T;
}

export const DEFAULT_DISPATCH_CUSTOM_EVENT_OPTION: Required<DispatchCustomEventOption<unknown>> = {
    target: window.document,
    bubble: true,
    canBeCancelled: true,
    detail: undefined,
};

export const dispatchCustomEvent = <T>(
    eventName: string,
    options: DispatchCustomEventOption<T> = DEFAULT_DISPATCH_CUSTOM_EVENT_OPTION as DispatchCustomEventOption<T>,
) => {
    const customEvent = new CustomEvent(eventName, {
        bubbles: defined<boolean>(options.bubble, DEFAULT_DISPATCH_CUSTOM_EVENT_OPTION.bubble),
        cancelable: defined<boolean>(options.canBeCancelled, DEFAULT_DISPATCH_CUSTOM_EVENT_OPTION.canBeCancelled),
        detail: options.detail,
    });

    defined<EventTarget>(options.target, DEFAULT_DISPATCH_CUSTOM_EVENT_OPTION.target).dispatchEvent(customEvent);
};

export const listenToCustomEvent = <T>(
    eventName: string,
    listener: (evt: CustomEvent<T>) => void,
    target: EventTarget = window.document,
) => {
    const eventListener = (evt: Event) => {
        listener(evt as CustomEvent<T>);
    };

    target.addEventListener(eventName, eventListener);
    return () => target.removeEventListener(eventName, eventListener);
};
