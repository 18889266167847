import { InitOptions, ResourceLanguage } from 'i18next';

import {
    Language,
    DEFAULT_LANGUAGE,
    IS_NODE_ENV_TEST,
    IS_NODE_ENV_DEVELOPMENT,
    IS_NODE_ENV_PRODUCTION,
} from '../../variables';

import en from '../../assets/i18n/en.json';
import de from '../../assets/i18n/de.json';
import { setupI18n } from '@climatepartner/utils';
import { DateFormat } from 'src/plugins/translations/helpers/formatDate';

export const CUSTOM_LANGUAGE_CHANGE_KEY = 'custom-language-change';

const TRANSLATION_RESOURCES: Record<Language, ResourceLanguage> = {
    'de-DE': de,
    'en-GB': en,
};

export const LANGUAGE_DATE_MAP: Record<Language, DateFormat> = {
    'de-DE': 'dd.MM.yyyy',
    'en-GB': 'dd/MM/yyyy',
};

export const LANGUAGE_SHORT_DATE_MAP: Record<Language, DateFormat> = {
    'de-DE': 'MM.yyyy',
    'en-GB': 'MM/yyyy',
};

export const I18nextInit: InitOptions = {
    resources: TRANSLATION_RESOURCES,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: IS_NODE_ENV_DEVELOPMENT,
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    saveMissing: !IS_NODE_ENV_PRODUCTION,
    missingKeyHandler: async (_lngs: readonly string[], namespace: string, key: string): Promise<void> => {
        if (IS_NODE_ENV_TEST) {
            throw Error("Missing translation ns='" + namespace + "' key='" + key + "'");
        }

        if (IS_NODE_ENV_DEVELOPMENT) {
            try {
                await fetch(
                    `http://localhost:12345/missing/${encodeURIComponent(namespace)}?key=${encodeURIComponent(key)}`,
                    { method: 'PUT' },
                );
            } catch (e) {
                // ignore
            }
        }
    },
    react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a', 'b'],
        transWrapTextNodes: 'span', // HINT: fixing https://github.com/facebook/react/issues/11538
    },
};

export const i18n = setupI18n(I18nextInit);
