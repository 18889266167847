import { User } from '../../types';
import { AbstractMonitor, Organisation } from './AbstractMonitor';

export class NoMonitor extends AbstractMonitor {
    action(...args: unknown[]): void {
        console.log(...args);
    }

    error(...args: unknown[]): void {
        console.error(...args);
    }
    setUser(_user: User): void {
        /* do nothing*/
    }

    setAccount(_organisation: Organisation): void {
        /* do nothing*/
    }

    start(): void {
        /* do nothing*/
    }

    disable(): void {
        /* do nothing*/
    }
}
