import { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';

import { LanguageButton } from './LanguageButton';

const LanguagePicker = () => {
    const {
        i18n: { changeLanguage },
    } = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);

    const handelLanguageClick = useCallback(
        (toLanguage: string) => {
            changeLanguage(toLanguage);
        },
        [changeLanguage],
    );

    return (
        <Stack ref={rootRef} direction="row" divider={<Divider orientation="vertical" flexItem />}>
            <LanguageButton language="en-GB" onLanguageClick={handelLanguageClick} />
            <LanguageButton language="de-DE" onLanguageClick={handelLanguageClick} />
        </Stack>
    );
};

export default LanguagePicker;
