import { PropsWithChildren } from 'react';

import { useTheme } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';

import { ThemeProvider as CPThemeProvider } from '@climatepartner/components';
import { getGlobalStyles, themeOptions } from '../helpers/themeOptions';

export const ThemeProvider = ({ children }: PropsWithChildren<unknown>) => {
    const theme = useTheme();

    return (
        <CPThemeProvider mode="light" theme={themeOptions}>
            <GlobalStyles styles={getGlobalStyles(theme)} />
            {children}
        </CPThemeProvider>
    );
};
