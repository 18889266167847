import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { AbstractMonitor } from './AbstractMonitor';
import { NoMonitor } from './NoMonitor';
import { getUserOrganization, Organisation } from '../../helpers/cim';
import { useAuth } from '../../plugins/auth/AuthProvider';

export const DEFAULT_MONITOR: AbstractMonitor = new NoMonitor();
export const MonitorContext = createContext<AbstractMonitor>(DEFAULT_MONITOR);

export interface MonitorProviderProps {
    monitor?: AbstractMonitor;
}

export const MonitorProvider = ({ children, monitor = DEFAULT_MONITOR }: PropsWithChildren<MonitorProviderProps>) => {
    const { user, isAuthenticated } = useAuth();
    const { accessToken } = user ?? {};
    const [userIsSet, setUserIsSet] = useState(false);
    const [organisationIsSet, setOrganisationIsSet] = useState(false);

    useEffect(() => {
        if (user && !userIsSet) {
            monitor.setUser(user);
            setUserIsSet(true);
        }
    }, [user, monitor, userIsSet]);

    useEffect(() => {
        if (accessToken && !organisationIsSet) {
            getUserOrganization(window.$cp.cimServiceApiUrl, accessToken)
                .then((organisation) => {
                    monitor.setAccount(mapOrganisationToAccountTrackingDetails(organisation));
                    setOrganisationIsSet(true);
                })
                .catch((error) => {
                    monitor.error(`Failed to set tracking organisation. Error: ${error.message}`, { error });
                });
        }
    }, [monitor, accessToken, isAuthenticated, organisationIsSet]);

    return <MonitorContext.Provider value={monitor}>{children}</MonitorContext.Provider>;
};

const mapOrganisationToAccountTrackingDetails = (organisation: Organisation) => ({
    name: organisation.name,
    slug: organisation.slug,
    isTest: organisation.isTestOrganisation,
    type: organisation.type,
});
