import { CustomEventBus } from './custom.event.bus';
import { DomainEvent, DomainEventType } from './DomainEvent';

export type EventBusUnsubscribe = () => void;

export interface EventBus<T> {
    emit(event: DomainEvent): void;
    subscribe(type: DomainEventType, handler: (event: T) => void, options?: any): EventBusUnsubscribe;
}

export const CustomEventBuss = new CustomEventBus();
