import { User } from '../../types';
import { decodeJwtToken } from '@climatepartner/utils';

export type AbstractMonitorContext = Record<string, unknown>;

export type Organisation = {
    isTest: boolean;
    slug: string;
    name: string;
    type: string;
};

export abstract class AbstractMonitor {
    abstract action(action: string, context?: AbstractMonitorContext): void;

    abstract error(error: unknown, context?: AbstractMonitorContext): void;
    abstract error(error: unknown, context?: AbstractMonitorContext): void;
    abstract setUser(user: User): void;
    abstract setAccount(organisation: Organisation): void;

    abstract start(): void;
    abstract disable(): void;

    getContext = (): { userId: string | undefined } => {
        let userId = undefined;
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            const decodedToken = decodeJwtToken(accessToken);
            if (decodedToken) {
                userId = decodedToken?.sub;
            }
        }

        return { userId };
    };
}
