import { DomainEvent, DomainEventType } from './DomainEvent';
import { EventBus, EventBusUnsubscribe } from './event.bus';

export class CustomEventBus implements EventBus<CustomEvent> {
    emit({ type, detail }: DomainEvent) {
        document.dispatchEvent(
            new CustomEvent(type, {
                detail,
                bubbles: true,
            }),
        );
    }

    subscribe<K extends DomainEventType>(
        type: K,
        handler: (event: CustomEvent<any>) => void,
        options?: AddEventListenerOptions,
    ): EventBusUnsubscribe {
        const resolve = (event: Event) => {
            if (event instanceof CustomEvent && Object.keys(DomainEventType).includes(event.type)) {
                handler(event);
            }
        };

        document.addEventListener(type, resolve, options);

        return () => {
            document.removeEventListener(type, resolve, options);
        };
    }
}
