import { useContext } from 'react';
import { LoggerContext } from './LoggerProvider';
import type { LogContext, AbstractLogger, LogLevel } from './AbstractLogger';

export const LogLevelValues: Array<LogLevel> = ['DEBUG', 'INFO', 'WARN', 'ERROR'];
export const isLogLevelFactory = (level: LogLevel) => {
    const indexLevel = LogLevelValues.indexOf(level);
    return (fnLevel: LogLevel): boolean => {
        const indexFnLevel = LogLevelValues.indexOf(fnLevel);
        return indexFnLevel >= indexLevel;
    };
};

export const useLogger = (): AbstractLogger => {
    const [logger, level] = useContext(LoggerContext);
    const isLogLevel = isLogLevelFactory(level);
    //intercept by using log level#
    return {
        debug: (message: string, context?: LogContext) => {
            if (isLogLevel('DEBUG')) {
                logger.debug(message, context);
            }
        },
        error: (message: string, context?: LogContext) => {
            if (isLogLevel('ERROR')) {
                logger.error(message, context);
            }
        },
        info: (message: string, context?: LogContext) => {
            if (isLogLevel('INFO')) {
                logger.info(message, context);
            }
        },
        warn: (message: string, context?: LogContext) => {
            if (isLogLevel('WARN')) {
                logger.warn(message, context);
            }
        },
    };
};
