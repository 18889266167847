import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { AccessDeniedPage } from '@climatepartner/components';

import { useAuth } from './plugins/auth/AuthProvider';
import Homepage from './pages/Homepage';
import { Layout } from './components/Layout';
import { Box } from '@mui/material';
import { AuthProvider } from './plugins/auth/AuthProvider';
import { useServices } from './helpers/useServices';

const router = createBrowserRouter([
    {
        Component: () => (
            <AuthProvider>
                <AppGuard />
            </AuthProvider>
        ),
        children: [
            {
                path: '/',
                element: <Homepage />,
            },
            {
                path: '*',
                element: <Navigate to="/" />,
            },
        ],
    },
]);

const AppRoutes = () => {
    return <RouterProvider router={router} />;
};

const AppGuard = () => {
    const auth = useAuth();
    const hasAccessToReductionForms = auth.hasPermission('reduction-forms.access');
    const enabledServicesUrls = useServices();
    const hasEnabledServices = enabledServicesUrls.length > 0;
    const canViewApp = hasAccessToReductionForms || hasEnabledServices;

    if (!canViewApp) {
        return (
            <Box mt={2}>
                <AccessDeniedPage />
            </Box>
        );
    }

    return <Layout />;
};

export default AppRoutes;
