import { Theme } from '@mui/material/styles';

const sxStyles = ({ palette }: Theme) => ({
    title: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '23px',
        styleName: 'Typography/H3',
        fontFamily: 'IBM Plex Sans',
        textAlign: 'center',
        colour: palette.primary.main,
        marginTop: '48px',
        marginBottom: '8px',
    },
    description: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        styleName: 'Typography/Body 1',
        fontFamily: 'IBM Plex Sans',
        textAlign: 'center',
        colour: palette.primary.main,
        marginTop: '8px',
        marginBottom: '60px',
    },
    icon: {
        color: '#003C91',
        fontSize: '50px',
        textAlign: 'center',
    },
    paper: {
        width: '540px',
        height: '281px',
        borderRadius: '8px',
    },
});

export default sxStyles;
