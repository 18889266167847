import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useDimension = () => {
    const theme = useTheme();

    return {
        mobile: useMediaQuery(theme.breakpoints.only('mobile' as any)),
        tablet: useMediaQuery(theme.breakpoints.only('tablet' as any)),
        desktop: useMediaQuery(theme.breakpoints.up('desktop' as any)),
    };
};
