import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import sxStyles from './Styles';
import { Construction } from '@mui/icons-material';
import { getNsTrans } from '../../plugins/translations';

const Trans = getNsTrans('maintenancePage');

export const MaintenancePage = () => {
    return (
        <Stack flex={1} justifyContent="center" alignItems="center">
            <Paper sx={(theme) => sxStyles(theme).paper}>
                <Typography sx={(theme) => sxStyles(theme).title}>
                    <Trans>title</Trans>
                </Typography>
                <Box sx={(theme) => sxStyles(theme).icon}>
                    <SvgIcon fontSize="inherit">
                        <Construction />
                    </SvgIcon>
                </Box>
                <Typography sx={(theme) => sxStyles(theme).description}>
                    <Trans>description</Trans>
                </Typography>
            </Paper>
        </Stack>
    );
};
