import { useAuth as authenticateWithFrontegg } from '@climatepartner/hooks';

export const runFronteggAuth = () => {
    const auth = authenticateWithFrontegg();

    return {
        ...auth,
        isCpEmployee: auth.hasPermission('employee.default'),
    };
};
