type CIMOrganisation = {
    name: string;
    type: string;
    slug: string;
    sandbox: boolean;
};

export type Organisation = {
    name: string;
    slug: string;
    isTestOrganisation: boolean;
    type: string;
};

export function getUserOrganization(baseUrl: string, accessToken: string): Promise<Organisation> {
    const url = `${baseUrl}/user/organization`;

    return window
        .fetch(url, {
            method: 'GET',

            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then(async (response) => {
            if (!response.ok) {
                throw new Error(`Response is not a 2xx.`);
            }

            return response.json();
        })
        .then((response) => mapCIMAccountToOrganisation(response.result));
}

export const getUserOrganizationName = async (baseUrl: string, accessToken: string): Promise<string> => {
    const organisation = await getUserOrganization(baseUrl, accessToken);

    if (!organisation) {
        return '';
    }

    return organisation.name;
};

const mapCIMAccountToOrganisation = (organisation: CIMOrganisation): Organisation => ({
    name: organisation.name,
    slug: organisation.slug,
    isTestOrganisation: organisation.sandbox,
    type: organisation.type,
});
