import { getEnabledCpServices, getServiceUrls } from '@climatepartner/utils';
import { useAuth } from '../plugins/auth/AuthProvider';
import { AUTH_ENV } from '../variables';
import { AccessTokenServiceNames } from './constants';

export type ServiceLink = {
    id: string;
    url: string;
};

export const useServices = () => {
    const { hasPermission, user } = useAuth();
    const enabledServices = getServiceUrls({
        env: AUTH_ENV,
        userHasPermission: hasPermission,
    }).filter(({ userHasAccess }) => userHasAccess);

    return normaliseServiceLinks(enabledServices, { organisationId: user?.organizationSlug ?? '' });
};

export const normaliseServiceLinks = (
    enabledServices: ServiceLink[],
    options: { organisationId: string },
): ServiceLink[] => {
    return [
        filterEnabledCpServices(options.organisationId),
        normaliseLinkIds,
        addCCFSolutionsIfNecessary,
        cleanDuplicates,
    ].reduce<ServiceLink[]>((acc, reducer) => {
        return reducer(acc);
    }, enabledServices);
};

const filterEnabledCpServices =
    (organisationId: string) =>
    (enabledServices: ServiceLink[]): ServiceLink[] => {
        const enabledCpServices = getEnabledCpServices(
            enabledServices.reduce(
                (acc, { id, url }) => ({
                    ...acc,
                    [id]: url,
                }),
                {},
            ),
            {
                [organisationId]: {
                    default: 'homescreen',
                    services: enabledServices.map(({ id }) => id),
                },
            },
        );

        const enabledCpServicesIds = Object.keys(enabledCpServices);

        return enabledServices.filter(({ id }) => enabledCpServicesIds.includes(id));
    };

const normaliseLinkIds = (enabledServices: ServiceLink[]): ServiceLink[] => {
    const servicesMapping: Record<string, string> = {
        'network-purchaser': 'network',
        'network-supplier': 'network',
    } as const;

    const mappedServiceIds: Record<string, true> = {};

    return enabledServices.reduce((acc, { id, url }) => {
        const mappedServiceId = servicesMapping[id] ?? id;

        if (mappedServiceId in mappedServiceIds) {
            return acc;
        }

        mappedServiceIds[mappedServiceId] = true;

        acc.push({
            id: mappedServiceId,
            url: url,
        });

        return acc;
    }, [] as ServiceLink[]);
};

const addCCFSolutionsIfNecessary = (enabledServices: ServiceLink[]): ServiceLink[] => {
    const cfcServiceNames = Object.values(AccessTokenServiceNames) as string[]; ///.filter((id) => id !== cfcServiceId) as string[];

    const cfcUrl = enabledServices
        .sort((a, b) => a.id.localeCompare(b.id))
        .find(({ id }) => cfcServiceNames.includes(id))?.url;

    if (!cfcUrl) {
        return enabledServices;
    }

    return enabledServices.concat(
        cfcServiceNames.map((serviceId) => ({
            id: serviceId,
            url: cfcUrl,
        })),
    );
};

const cleanDuplicates = (enabledServices: ServiceLink[]): ServiceLink[] => {
    return [...new Map(enabledServices.map((service) => [service.id, service])).values()];
};
