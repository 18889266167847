import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { I18nextProvider as ReactI18nextProvider, useTranslation } from 'react-i18next';
import { LanguageStore } from '../helpers/language.store';
import { parseLanguage } from '../helpers';
import { i18n, LANGUAGE_DATE_MAP, LANGUAGE_SHORT_DATE_MAP } from '../i18next';
import { FormatDateProvider } from './FormatDate';
import { DomainEventType } from '../helpers/DomainEvent';
import { ChangeLanguageDomainEventDetail } from '../helpers/ChangeLanguageDomainEvent';
import { CustomEventBuss } from '../helpers/event.bus';

export const I18nextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const {
        i18n: { language, changeLanguage },
    } = useTranslation();

    // TODO: this looks like a really nasty hack. At first precision I thought it would be nice to replace useEffect with
    // native i18next.on('languageChange'). However, i18next.emit seems not working or require some specific config.
    // Consider to provide clearer solution
    useEffect(() => {
        window.$language = language;
        LanguageStore.set(language);
    }, [language]);

    useEffect(() => {
        return CustomEventBuss.subscribe(
            DomainEventType.CHANGE_LANGUAGE,
            ({ detail: { language } }: CustomEvent<ChangeLanguageDomainEventDetail>) => {
                changeLanguage(parseLanguage(language));
            },
        );
    }, [changeLanguage]);

    return (
        <ReactI18nextProvider i18n={i18n}>
            <FormatDateProvider languageDateMap={LANGUAGE_DATE_MAP} languageShortDateMap={LANGUAGE_SHORT_DATE_MAP}>
                {children}
            </FormatDateProvider>
        </ReactI18nextProvider>
    );
};
