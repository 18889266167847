import './App.css';

import AppRoutes from './AppRoutes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MaintenancePage } from './pages/MaintenancePage/MaintenancePage';
import { FeatureToggle } from './plugins/featureToggles';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 30000,
        },
    },
});

const App = () => (
    <QueryClientProvider client={queryClient}>
        <FeatureToggle not featureKey="maintenance">
            <AppRoutes />
        </FeatureToggle>
        <FeatureToggle featureKey="maintenance">
            <MaintenancePage />
        </FeatureToggle>
    </QueryClientProvider>
);

export default App;
