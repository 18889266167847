export const DATADOG_APPLICATION_ID = import.meta.env?.VITE_DATADOG_APPLICATION_ID as string;
export const DATADOG_CLIENT_TOKEN = import.meta.env?.VITE_DATADOG_CLIENT_TOKEN as string;
export const DATADOG_SITE = 'datadoghq.eu';
export const MODE = import.meta.env?.MODE;
export const GIT_SHA = import.meta.env?.VITE_GIT_SHA as string;
export const GIT_REF = import.meta.env?.VITE_GIT_REF as string;

// TODO: separate env file for test
export type Language = 'en-GB' | 'de-DE';
export const LanguageValues = ['de-DE', 'en-GB'];
export const DEFAULT_LANGUAGE: Language = 'en-GB';

export const IS_NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_NODE_ENV_TEST = process.env.NODE_ENV === 'test';
export const IS_NODE_ENV_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const REPORTED_YEAR = 2021;

export const AUTH_ENV = resolveAuthEnv(
    import.meta.env.VITE_FRONTEGG_ENVIRONMENT ?? import.meta.env.NODE_ENV ?? 'production',
);

function resolveAuthEnv(env: string) {
    switch (true) {
        case /^dev((elopment)?)$/.test(env):
            return 'dev';
        case /^(stg|staging)$/.test(env):
            return 'staging';
        default:
            return 'prod';
    }
}
