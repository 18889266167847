import * as configcat from 'configcat-js';

import { IJSAutoPollOptions } from 'configcat-js';
import { IConfigCatClient, LogLevel } from 'configcat-common';

import { dispatchCustomEvent } from './customEvents';

export const CONFIG_CHANGED_EVENT = 'CONFIG_CHANGED_EVENT';

export const IS_NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';

export const DEFAULT_CONFIG_CAT_CONFIG: IJSAutoPollOptions = {
    dataGovernance: configcat.DataGovernance.EuOnly,
    pollIntervalSeconds: 60,
    maxInitWaitTimeSeconds: 0,
    logger: configcat.createConsoleLogger(IS_NODE_ENV_PRODUCTION ? LogLevel.Error : LogLevel.Warn),
    configChanged: () => dispatchCustomEvent(CONFIG_CHANGED_EVENT),
};

class FeatureToggleClient {
    constructor(apiKey: string, config: IJSAutoPollOptions, userStoreAdapter?: string) {
        this.configCatClient = configcat.createClientWithAutoPoll(apiKey, config);
        this.userStoreAdapter = userStoreAdapter;
    }

    configCatClient: IConfigCatClient;
    userStoreAdapter: string | undefined;

    getUserIdentifier = () => {
        return this.userStoreAdapter;
    };
}

export let featureToggleClient: FeatureToggleClient;

let configCatClient: IConfigCatClient;

export const FeatureToggleInit = (apiKey: string, userStoreAdapter?: string) => {
    if (typeof configCatClient === 'undefined') {
        featureToggleClient = new FeatureToggleClient(apiKey, DEFAULT_CONFIG_CAT_CONFIG, userStoreAdapter);
    }

    if (!IS_NODE_ENV_PRODUCTION) {
        featureToggleClient.configCatClient?.getAllValues(
            (vals) => {
                console.groupCollapsed('Available feature toggle keys');
                console.table(vals);
                console.groupEnd();
            },
            {
                identifier: userStoreAdapter ?? '@climatepartner.com',
            },
        );
    }
};
