import { memo } from 'react';

import Typography from '@mui/material/Typography';
import SentimentDissatisfiedOutlined from '@mui/icons-material/SentimentDissatisfiedOutlined';

import { Button, ErrorPage } from '@climatepartner/components';
import { getNsTrans } from '../plugins/translations';

interface Props {
    error?: Error;
    errorAction?: JSX.Element;
}

const GenericErrorPage = ({ error }: Props) => {
    const Trans = getNsTrans('genericErrorPage');

    const contactEmail = 'service@climatepartner.com';

    return (
        <ErrorPage title={''} subtitle={'An error occurred'} icon={<SentimentDissatisfiedOutlined />} error={error}>
            <Typography paragraph>
                <p>
                    <Trans>descriptionFirst</Trans>
                </p>
                <p>
                    <Trans>descriptionSecond</Trans>
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </p>
                <p>
                    <Trans>descriptionThird</Trans>
                </p>
            </Typography>
            <ReloadAction buttonText={<Trans>reloadButton</Trans>} />
        </ErrorPage>
    );
};

export default memo((props: Props) => <GenericErrorPage {...props} />);

export const ReloadAction = ({ buttonText }: { buttonText: JSX.Element }) => {
    const handleButtonClick = () => {
        window.location.reload();
    };

    return (
        <Button variant="outlined" color="primary" onClick={handleButtonClick} sx={{ mt: 3 }}>
            {buttonText}
        </Button>
    );
};
