import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { listenToCustomEvent } from './customEvents';
import { FeatureToggleKey } from './FeatureToggleKey';
import { isFeatureToggleEnabled } from './isFeatureToggleEnabled';
import { CONFIG_CHANGED_EVENT, featureToggleClient } from './featureToggles';
import { useAuth } from '../auth/AuthProvider';

export const useFeatureToggle = (
    key: FeatureToggleKey,
    defaultValue = false,
    userId: string | undefined = undefined,
) => {
    const [allowed, setAllowed] = useState<boolean>();
    const { user } = useAuth();
    const userIdentifier = userId ?? user?.email ?? '';

    const triggerFeatureToggle = useCallback(async () => {
        if (typeof featureToggleClient.configCatClient !== 'undefined') {
            const value = await isFeatureToggleEnabled(key, defaultValue, userIdentifier);
            setAllowed(value);
        } else {
            console.error('Missing config cat configuration and provider');
        }
    }, [defaultValue, key, userIdentifier]);

    useLayoutEffect(() => {
        return listenToCustomEvent(CONFIG_CHANGED_EVENT, triggerFeatureToggle);
    });

    useEffect(() => {
        triggerFeatureToggle();
    }, [triggerFeatureToggle]);

    return allowed;
};
