import DeblurIcon from '@mui/icons-material/Deblur';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { Icon, Teaser } from '@climatepartner/components';

import { AccessTokenServiceNames } from '../helpers/constants';
import { useDimension } from '../helpers/useDimension';
import { getNsTrans } from '../plugins/translations';
import { ServiceLink } from '../helpers/useServices';

type ServiceId = ServiceLink['id'];

type Props = {
    userServiceLinks: ServiceLink[];
};

const Trans = getNsTrans('homepage-tiles');

type Tile = {
    tileTitle: ReactElement;
    tileIcon: ReactElement;
    buttonText: ReactElement;
    tileText: ReactElement;
    sortOrder: number;
};

export const HomepageTiles = ({ userServiceLinks }: Props) => {
    const orderedTiles: Record<ServiceId, Tile> = {
        fpc: {
            tileTitle: <Trans>Measure Carbon Footprint</Trans>,
            tileIcon: <DeblurIcon />,
            buttonText: <Trans>Go To Calculations</Trans>,
            tileText: (
                <Trans>
                    Understand your carbon emissions by calculating the Corporate Carbon Footprint (CCF) and the Product
                    Carbon Footprint (PCF).
                </Trans>
            ),
            sortOrder: 0,
        },
        fpm: {
            tileTitle: <Trans>offsetsTitle</Trans>,
            tileIcon: <Icon name="offsetting" />,
            buttonText: <Trans>offsetsButton</Trans>,
            tileText: <Trans>offsetsDescription</Trans>,
            sortOrder: 3,
        },
        'reduction-homepage-frontend': {
            tileTitle: <Trans>reductionsTitle</Trans>,
            tileIcon: <TrendingDownIcon />,
            buttonText: <Trans>reductionsButton</Trans>,
            tileText: <Trans>reductionsDescription</Trans>,
            sortOrder: 2,
        },
        network: {
            tileTitle: <Trans>supplyNetworkTitle</Trans>,
            tileIcon: <Icon name="supplyNetwork" />,
            buttonText: <Trans>supplyNetworkButton</Trans>,
            tileText: <Trans>supplyNetworkDescription</Trans>,
            sortOrder: 5,
        },
        'transparency-dashboard-frontend': {
            tileTitle: <Trans>transparencyTitle</Trans>,
            tileIcon: <Icon name="transparency" />,
            buttonText: <Trans>transparencyButton</Trans>,
            tileText: <Trans>transparencyDescription</Trans>,
            sortOrder: 4,
        },
        'analytics.basic': {
            tileTitle: <Trans>analyticsTitle</Trans>,
            tileIcon: <BarChartIcon />,
            buttonText: <Trans>analyticsButton</Trans>,
            tileText: <Trans>analyticsDescription</Trans>,
            sortOrder: 1,
        },
    };

    const { mobile } = useDimension();
    const columnSize = mobile ? 12 : 6;
    const sortTilesByOrder = ([, current]: [string, Tile], [, next]: [string, Tile]) =>
        current.sortOrder - next.sortOrder;

    const cfcServiceNames = Object.values(AccessTokenServiceNames) as string[];
    const cfc = userServiceLinks.find(({ id }) => cfcServiceNames.includes(id));
    return (
        <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h2" sx={{ marginBottom: 2, fontSize: '20px', fontWeight: 600 }}>
                <Trans>Access your ClimatePartner services</Trans>
            </Typography>

            <Grid container spacing={3}>
                {Object.entries(orderedTiles)
                    .sort(sortTilesByOrder)
                    .map(([serviceId, tile]) => {
                        const url =
                            serviceId === 'fpc' && cfc
                                ? cfc.url
                                : userServiceLinks.find(({ id }) => id === serviceId)?.url;

                        if (!url) {
                            return null;
                        }

                        const { tileTitle, tileIcon, buttonText, tileText } = tile;

                        return (
                            <Grid item xs={columnSize} key={`tile-${serviceId}`}>
                                <Box sx={{ position: 'relative', height: '100%' }}>
                                    <Teaser Icon={tileIcon} variant={'contained'} sx={{ height: '100%' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                paddingBottom: `${24 + 16}px`,
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '1.1rem', fontWeight: 700, marginBottom: 2 }}>
                                                {tileTitle}
                                            </Typography>

                                            <Typography paragraph sx={{ fontSize: '1rem' }}>
                                                {tileText}
                                            </Typography>

                                            <Button
                                                variant="outlined"
                                                href={url}
                                                color="inherit"
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 24,
                                                }}
                                            >
                                                {buttonText}
                                            </Button>
                                        </Box>
                                    </Teaser>
                                </Box>
                            </Grid>
                        );
                    })}
            </Grid>
        </Box>
    );
};
