import { memo, PropsWithChildren } from 'react';

import { FeatureToggleKey } from '../FeatureToggleKey';
import { useFeatureToggle } from '../useFeatureToggle';

export interface FeatureToggleProps {
    featureKey: FeatureToggleKey;
    defaultValue?: boolean;
    userIdentifier?: string;
    not?: boolean;
}

export const FeatureToggle = memo(
    ({
        featureKey,
        defaultValue = false,
        not = false,
        userIdentifier,
        children,
    }: PropsWithChildren<FeatureToggleProps>) => {
        const isFeatureToggleOn = useFeatureToggle(featureKey, defaultValue, userIdentifier);
        const shouldRenderChildren = not ? !isFeatureToggleOn : isFeatureToggleOn;

        if (shouldRenderChildren) {
            return <>{children}</>;
        }

        return null;
    },
);
