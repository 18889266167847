import { Spinner } from '@climatepartner/components';
import { Box, Typography } from '@mui/material';
import { Suspense, lazy, memo } from 'react';
import { useAuth } from '../plugins/auth/AuthProvider';
import { HomepageTiles } from '../components/HomepageTiles';
import { getNsTrans } from '../plugins/translations';
import { useServices } from '../helpers/useServices';

const Trans = getNsTrans('homepage');

const FORMS_SERVICE_NAME = 'reduction-forms';

const Homepage = () => {
    const { user, hasPermission } = useAuth();
    const shouldShowForms = hasPermission(`${FORMS_SERVICE_NAME}.access`);
    const welcomeName = user?.name ? `, ${user.name}` : '';
    const menuLinks = useServices();

    return (
        <Box>
            <Typography
                sx={{
                    marginBottom: 4,
                    fontWeight: 700,
                    fontSize: '24px',
                }}
                color={'primary'}
            >
                <Trans>Welcome</Trans>
                {welcomeName}
            </Typography>

            {menuLinks.length > 0 && (
                <>
                    <HomepageTiles userServiceLinks={menuLinks} />
                </>
            )}

            {shouldShowForms ? RenderReductionForms() : null}
        </Box>
    );
};

const RenderReductionForms = () => {
    const ReductionFormsSection = lazy(() => import('../containers/DataProviderSection'));

    return (
        <Suspense fallback={<Spinner />}>
            <ReductionFormsSection />
        </Suspense>
    );
};

export default memo(Homepage);
