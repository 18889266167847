import { createContext } from 'react';
import { DateFormat } from '../../helpers/formatDate';
import { DEFAULT_FORMAT_DATE_CONFIG } from './FromatDateConfig';

export type FormatDateMap = {
    map: Record<string, DateFormat>;
    defaultFormat: DateFormat;
};
export type FormatDateContextValue = {
    date: FormatDateMap;
    shortDate: FormatDateMap;
};
export const FormatDateContext = createContext<FormatDateContextValue>({
    date: {
        map: {},
        defaultFormat: DEFAULT_FORMAT_DATE_CONFIG.date,
    },
    shortDate: {
        map: {},
        defaultFormat: DEFAULT_FORMAT_DATE_CONFIG.shortDate,
    },
});
