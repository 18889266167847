import { createContext, PropsWithChildren } from 'react';
import { AbstractLogger, LogLevel } from './AbstractLogger';
import { NoLogger } from './NoLogger';

export const DEFAULT_LOGGER: AbstractLogger = new NoLogger();
export const DEFAULT_LOGLEVEL: LogLevel = 'DEBUG';
export const LoggerContext = createContext<[AbstractLogger, LogLevel]>([DEFAULT_LOGGER, DEFAULT_LOGLEVEL]);

export interface LoggerProviderProps {
    logger?: AbstractLogger;
    logLevel?: LogLevel;
}

export const LoggerProvider = ({
    children,
    logger = DEFAULT_LOGGER,
    logLevel = DEFAULT_LOGLEVEL,
}: PropsWithChildren<LoggerProviderProps>) => (
    <LoggerContext.Provider value={[logger, logLevel]}>{children}</LoggerContext.Provider>
);
