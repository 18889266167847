import { DateFormat } from '../../helpers/formatDate';

export type FormatDateConfig = {
    date: DateFormat;
    shortDate: DateFormat;
};
export const DEFAULT_FORMAT_DATE_CONFIG: FormatDateConfig = {
    date: 'dd/MM/yyyy',
    shortDate: 'MM/yyyy',
};
